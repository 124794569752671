import { createBrowserRouter, RouteObject } from "react-router-dom";
import IndexPage from "./pages/index";
import S3UploadPage from "./pages/s3upload/index";

const sharedRouters: RouteObject[] = [
  {
    id: "index",
    path: "/",
    element: <IndexPage />
  },
  {
    id: "s3upload",
    path: "/s3upload",
    element: <S3UploadPage />
  }
];

// const publicRouters: RouteObject[] = [];

// const privateRoters: RouteObject[] = [];

export const routers = () => {
  return createBrowserRouter([...sharedRouters]);
};
