import React, { useEffect } from "react";

export default function Page() {
  useEffect(() => {
    window.addEventListener("message", handleMessageFromChild, false);
    return () => window.removeEventListener("message", handleMessageFromChild);
  }, []);

  const handleMessageFromChild = (e: MessageEvent) => {
    console.log(e.origin, e.data);
    if (
      e.origin.includes(process.env.REACT_APP_CHILD_WINDOW ?? "") ||
      e.data.type === undefined
    )
      return;
    console.log(e.data);
  };

  return (
    <main className="flex min-h-screen select-none flex-row items-center justify-center p-2">
      <div className="relative flex w-full flex-col place-items-center space-y-2 text-center md:w-4/5 lg:w-3/5">
        <h1 className="mb-3 hidden text-2xl font-semibold md:visible">
          The Impact Positive Company Platform
        </h1>
        <iframe
          src={`s3upload`}
          title={"S3 Uploading"}
          className="z-10 h-[300px] w-full"
        ></iframe>
      </div>
    </main>
  );
}
