import React from "react";
import { RouterProvider } from "react-router";
import "@fontsource/alegreya-sans";
import "@fontsource/alegreya-sans/400.css";
import "@fontsource/alegreya-sans/400-italic.css";

import { routers } from "./routes";

function App() {
  return (
    <React.Fragment>
      <RouterProvider router={routers()} />
    </React.Fragment>
  );
}

export default App;
