import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import FileUpload from "components/s3upload/FileUpload";
import UploadProgress from "components/s3upload/UploadProgress";
import UploadComplete from "components/s3upload/UploadComplete";
import UploadError from "components/s3upload/UploadError";
import { UploadContent, ProgressObject, UploadStates } from "types";

export default function Page() {
  const [state, setState] = useState<UploadStates>(UploadStates.UPLOAD_START);
  const [uploadProgress, setUploadProgress] = useState<ProgressObject>({
    progress: 0,
    statusContent: ""
  });
  const [errState, setErrState] = useState<string>("");

  const initialUploadedFile: UploadContent = {
    files: []
  };
  const [uploadedFile, setUploadedFile] =
    useState<UploadContent>(initialUploadedFile);

  const sendSignalToParent = (
    signalType: UploadStates,
    data: UploadContent | null | AxiosError | ProgressEvent
  ) => {
    if (window.parent) {
      window.parent.postMessage(
        {
          type: signalType,
          data: data
        },
        process.env.REACT_APP_TARGET_ORIGIN ?? ""
      );
    }
  };

  const handleErrorException = (
    status: "link" | "file",
    err: UploadContent | null | AxiosError | ProgressEvent
  ) => {
    setErrState(status);
    setState(UploadStates.ERROR);
    sendSignalToParent(UploadStates.ERROR, err);
  };

  const uploadComplete = (data: UploadContent) => {
    setUploadProgress({
      progress: 100,
      statusContent: "Upload completed!"
    });
    setState(UploadStates.UPLOAD_COMPLETE);
    setUploadedFile(data);
    sendSignalToParent(UploadStates.UPLOAD_COMPLETE, data);
  };

  const handleUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    setState(UploadStates.UPLOADING);
    sendSignalToParent(UploadStates.UPLOADING, null);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/s3bucket/file-order`,
        formData
      )
      .then(res => {
        const xhr = new XMLHttpRequest();
        xhr.open(
          "GET",
          `${process.env.REACT_APP_SERVER_URL}/api/s3bucket/file?client_id=${res.data}`
        );
        xhr.onprogress = function () {
          const realData = JSON.parse("[" + xhr.responseText + "]");
          if (realData[realData.length - 1].error) {
            console.log("error");
            handleErrorException("file", realData[realData.length - 1].error);
          }
          setUploadProgress({
            progress: realData[realData.length - 1].progress,
            statusContent: realData[realData.length - 1].content
          });
        };
        xhr.onloadend = function () {
          if (xhr.responseText) {
            setUploadProgress({
              progress: 100,
              statusContent: "Upload completed!"
            });
            const result = JSON.parse("[" + xhr.responseText + "]");
            if (result[result.length - 1].error) {
              handleErrorException("file", result[result.length - 1].error);
              return;
            }
            setTimeout(() => {
              uploadComplete(result[result.length - 1]);
            }, 500);
          }
        };

        xhr.onerror = function (err) {
          console.error(`File Uploading ERR :=> ${err}`);
          handleErrorException("file", err);
        };
        xhr.send();
      })
      .catch((err: AxiosError) => {
        console.error(`File Uploading ERR :=> ${err}`);
        handleErrorException("file", err);
      });
  };

  const handleUploadCancel = () => {
    setState(UploadStates.UPLOAD_EXIT);
    sendSignalToParent(UploadStates.UPLOAD_EXIT, null);
    setUploadProgress({
      progress: 0,
      statusContent: "Upload canceled"
    });
  };

  const handleUrlFetch = async (url: string) => {
    setState(UploadStates.UPLOADING);
    sendSignalToParent(UploadStates.UPLOADING, null);
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `${process.env.REACT_APP_SERVER_URL}/api/s3bucket/link?url=${url}`
    );
    xhr.onprogress = function () {
      const realData = JSON.parse("[" + xhr.responseText + "]");
      if (realData[realData.length - 1].error) {
        handleErrorException("link", realData[realData.length - 1].error);
      }
      setUploadProgress(realData[realData.length - 1].progress);
    };
    xhr.onloadend = function () {
      if (xhr.responseText) {
        setUploadProgress({
          progress: 100,
          statusContent: "Upload Completed"
        });
        const result = JSON.parse("[" + xhr.responseText + "]");
        if (result[result.length - 1].error) {
          handleErrorException("link", result[result.length - 1].error);
          return;
        }
        setTimeout(() => {
          uploadComplete(result[result.length - 1]);
        }, 500);
      }
    };

    xhr.onerror = function (err: ProgressEvent) {
      console.error(`Url Fetch ERR :=> ${err}`);
      handleErrorException("link", err);
    };
    xhr.send();
  };

  return (
    <div className="flex min-h-screen w-full select-none flex-col items-center justify-center bg-gray-50 text-center text-black">
      {(state === UploadStates.UPLOAD_START ||
        state === UploadStates.UPLOAD_EXIT) && (
        <FileUpload
          handleUpload={handleUpload}
          handleUrlFetch={handleUrlFetch}
        />
      )}
      {state === UploadStates.UPLOADING && (
        <UploadProgress uploading={uploadProgress} exit={handleUploadCancel} />
      )}
      {state === UploadStates.ERROR && (
        <UploadError exit={handleUploadCancel} errState={errState} />
      )}
      <UploadComplete
        exit={handleUploadCancel}
        uploadResult={uploadedFile}
        status={state}
      />
    </div>
  );
}
