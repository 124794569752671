import React from "react";
import { Icon } from "components/Icon";

interface PropsType {
  exit: () => void;
  errState: string;
}

const Component: React.FC<PropsType> = ({ exit, errState }) => {
  return (
    <div className="absolute z-10 flex h-screen w-full flex-col items-center justify-center bg-[#ac0000] transition-all duration-500">
      <button onClick={() => exit()} className="fixed right-3 top-3">
        <Icon icon={"Exit"} size={30} fill={"#fff"} />
      </button>
      <div className="flex flex-col items-center justify-center space-y-3 pb-6 pt-5">
        <Icon icon={"Error"} size={50} fill={"#fff"} />
        <h2 className="text-xl font-bold text-white">Oh no!</h2>
        <p className="mb-2 text-sm italic text-white">
          {errState === "link"
            ? "That web page blocked us!"
            : errState === "file"
            ? "Something went wrong!"
            : ""}
          <a
            className="cursor-pointer italic text-white underline"
            onClick={() => exit()}
          >
            Please try another {errState}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Component;
