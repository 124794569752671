import React from "react";
import { Icon } from "components/Icon";
import { ProgressObject } from "types";

interface PropsType {
  uploading: ProgressObject;
  exit: () => void;
}

const Component: React.FC<PropsType> = ({ uploading }) => {
  return (
    <div className="relative flex h-screen w-full flex-col items-center justify-between text-black transition-all">
      <div />
      <div className="flex flex-col items-center justify-center space-y-3 pb-6 pt-5">
        <Icon
          icon={"Uploading"}
          className="animate-spin"
          size={50}
          fill={"#beced8"}
        />
        <h2 className="text-xl font-bold">Uploading file</h2>
        <p className="mb-2 text-sm">Uploading and processing file</p>
      </div>
      {/* Uploading Progress Bar */}
      <div className="relative flex h-12 w-full items-center border-2 border-solid border-black bg-slate-500 p-0">
        <div
          className="absolute m-0 h-full border-r-2 border-solid border-black transition-all"
          style={{ width: `${uploading.progress}%`, background: "#cfaa44" }}
        />
        <p className="relative z-30 ml-2 pl-3 text-[15px] font-bold">
          {uploading.progress}%{" "}
          <i className="ml-1">{uploading.statusContent}</i>
        </p>
      </div>
      {/*  */}
    </div>
  );
};

export default Component;
