import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Icon } from "components/Icon";

interface PropsType {
  handleUpload: (param: File) => void;
  handleUrlFetch: (url: string) => void;
}

const Component: React.FC<PropsType> = ({ handleUpload, handleUrlFetch }) => {
  const [isDragEnter, setIsDragEnter] = useState<boolean>(false);
  const [fetchUrl, setFetchUrl] = useState<string>("");
  const [urlInputErr, setUrlInputErr] = useState<string>("");

  /**  Dropzone Hooks */
  const onDrop = useCallback((acceptedFiles: File[]) => {
    handleUpload(acceptedFiles[0]);
  }, []);

  const onDragEnter = useCallback(() => {
    setIsDragEnter(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragEnter(false);
  }, []);

  const onDragOver = useCallback(() => {}, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    multiple: false,
    onDragEnter,
    onDragLeave,
    onDragOver
  });
  /** --------------- */

  // Fetch Url input keydown event handler
  const handleUrlInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.key !== "Enter") return;
    urlInputValidate();
  };

  // Validate url input
  const urlInputValidate = () => {
    if (fetchUrl === "") {
      setUrlInputErr("Whoops! please use a valid url");
    }
    try {
      new URL(fetchUrl);
      setUrlInputErr("");
      handleUrlFetch(fetchUrl);
    } catch (error) {
      setUrlInputErr("Whoops! please use a valid url");
    }
  };

  return (
    <label
      htmlFor="dropzone-file"
      className="relative flex h-screen w-full cursor-pointer items-center justify-center p-2 text-black"
      {...getRootProps()}
    >
      <input id="dropzone-file" className="hidden" {...getInputProps()} />
      <div
        className="absolute h-screen w-full rounded-lg border-[3px] border-dashed border-[#f6ca5e] bg-[#f8d37565]"
        style={{ display: isDragEnter ? "block" : "none" }}
      ></div>
      <div className="flex w-full flex-col items-center justify-center pb-6 pt-5">
        <Icon icon={"Cloud"} size={50} fill={"#beced8"} />
        <h2 className="mt-2 text-2xl font-[900]">Upload a file</h2>
        <p className="m-1 text-[20px]">
          <span className="underline">Select a file</span> to upload, or drag
          and drop it here
        </p>
        <div className="mt-5 h-0 w-[200px] border-t-2 border-solid border-[#cfdfe9]"></div>
        <div
          className="mt-7 w-full select-none border-none sm:w-3/5 lg:w-2/5"
          onClick={(e: React.MouseEvent) => e.preventDefault()}
        >
          <div className="flex ">
            <input
              type="url"
              value={fetchUrl}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setFetchUrl(e.currentTarget.value)
              }
              autoFocus
              onKeyDown={handleUrlInputKeyDown}
              // eslint-disable-next-line
              className={`w-full border-[2px] border-solid py-2 pl-4 placeholder-[#bfe0eb] outline-none placeholder:font-serif placeholder:italic ${
                urlInputErr === "" ? "border-[#000]" : "border-[red]"
              }`}
              placeholder="https://..."
            />
            <button
              className={`cursor-pointer whitespace-nowrap border-[2px] border-l-[0] border-solid border-[#000] bg-[#f6ca5e] px-5 py-2 text-[18px] font-bold`}
              onClick={() => urlInputValidate()}
            >
              Fetch file from link
            </button>
          </div>
          {urlInputErr !== "" && (
            <p className="mt-2 w-full text-left text-sm text-[red]">
              {urlInputErr}
            </p>
          )}
        </div>
      </div>
    </label>
  );
};

export default Component;
