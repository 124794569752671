import React from "react";

interface IconProps {
  className?: string;
  fill?: string;
  size?: number;
  marginLeft?: number;
  margin?: number;
  icon: keyof typeof s3uploadIcons;
  style?: object;
}

export const Icon = ({
  className,
  fill,
  size,
  icon,
  marginLeft,
  margin,
  style
}: IconProps) => (
  <div
    className={className}
    style={{
      color: fill,
      width: size || 16,
      height: size || 16,
      marginLeft: marginLeft || 0,
      marginRight: margin || 0,
      fill: fill,
      ...style
    }}
  >
    {s3uploadIcons[icon]}
  </div>
);

const s3uploadIcons = {
  Cloud: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 -960 960 960"
      width="100%"
    >
      <path d="M250-160q-86 0-148-62T40-370q0-78 49.5-137.5T217-579q20-97 94-158.5T482-799q113 0 189.5 81.5T748-522v24q72-2 122 46.5T920-329q0 69-50 119t-119 50H510q-24 0-42-18t-18-42v-258l-83 83-43-43 156-156 156 156-43 43-83-83v258h241q45 0 77-32t32-77q0-45-32-77t-77-32h-63v-84q0-89-60.5-153T478-739q-89 0-150 64t-61 153h-19q-62 0-105 43.5T100-371q0 62 43.929 106.5Q187.857-220 250-220h140v60H250Zm230-290Z" />
    </svg>
  ),
  Uploading: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 -960 960 960"
      width="100%"
    >
      <path d="M196-331q-20-36-28-72.5t-8-74.5q0-131 94.5-225.5T480-798h43l-80-80 39-39 149 149-149 149-40-40 79-79h-41q-107 0-183.5 76.5T220-478q0 29 5.5 55t13.5 49l-43 43ZM476-40 327-189l149-149 39 39-80 80h45q107 0 183.5-76.5T740-479q0-29-5-55t-15-49l43-43q20 36 28.5 72.5T800-479q0 131-94.5 225.5T480-159h-45l80 80-39 39Z" />
    </svg>
  ),
  Complete: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 -960 960 960"
      width="100%"
    >
      <path d="M480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q75 0 140 24t117 66l-43 43q-44-35-98-54t-116-19q-145 0-242.5 97.5T140-480q0 145 97.5 242.5T480-140q145 0 242.5-97.5T820-480q0-30-4.5-58.5T802-594l46-46q16 37 24 77t8 83q0 85-31 158t-85 127q-54 54-127 84.5T480-80Zm-59-218L256-464l45-45 120 120 414-414 46 45-460 460Z" />
    </svg>
  ),
  Exit: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 -960 960 960"
      width="100%"
    >
      <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
    </svg>
  ),
  Error: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M220-80q-24 0-42-18t-18-42v-501l239-239h341q24 0 42 18t18 42v680q0 24-18 42t-42 18H220Zm0-60h520v-680H426L220-613.875V-140Zm260-149q14 0 24.5-10.5T515-324q0-14-10.5-24.5T480-359q-14 0-24.5 10.5T445-324q0 14 10.5 24.5T480-289Zm-28-143h60v-205h-60v205ZM220-140h520-520Z" />
    </svg>
  )
};
