import React from "react";
import { Icon } from "components/Icon";
import { S3UploadObject, UploadContent, UploadStates } from "types";
import { Link } from "react-router-dom";

interface PropsType {
  exit: () => void;
  uploadResult: UploadContent;
  status: UploadStates;
}

const Component: React.FC<PropsType> = ({ exit, uploadResult, status }) => {
  return (
    <div
      style={
        status === UploadStates.UPLOAD_COMPLETE
          ? { visibility: "visible", opacity: "1" }
          : { visibility: "hidden", opacity: "0" }
      }
      className="absolute z-10 flex h-screen w-full flex-col items-center justify-center bg-[#cbcf5d] transition-all duration-500"
    >
      <button onClick={() => exit()} className="fixed right-3 top-3">
        <Icon icon={"Exit"} size={30} fill={"#000"} />
      </button>
      <div className="flex flex-col items-center justify-center space-y-3 pb-6 pt-5">
        <Icon icon={"Complete"} size={50} fill={"#000000"} />
        <h2 className="text-xl font-bold">
          {uploadResult === undefined ? "Please Try Again" : "Upload Complete"}
        </h2>
        {typeof uploadResult.files === "object" &&
          uploadResult.files.length !== 0 && (
            <p className="mb-2 text-sm">
              {uploadResult.files.map((file: S3UploadObject) => (
                <React.Fragment key={file.file_name}>
                  <Link
                    className="cursor-pointer underline"
                    to={file?.s3_link as string}
                    target="_blank"
                  >
                    {file.original_name}
                  </Link>
                  {", "}
                </React.Fragment>
              ))}
              {uploadResult.files.length === 1 ? <i>is</i> : <i>are</i>} ready
              to use
            </p>
          )}
      </div>
    </div>
  );
};

export default Component;
