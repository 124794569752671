export enum UploadStates {
  UPLOAD_START = "Start Upload",
  UPLOAD_EXIT = "Exit Upload",
  UPLOADING = "uploading",
  UPLOAD_COMPLETE = "Upload Complete",
  ERROR = "Error Occured"
}

export interface S3UploadObject {
  file_name?: string;
  file_id?: string;
  s3_link?: string;
  size?: string | number;
  original_name?: string;
}

export interface UploadContent {
  files: S3UploadObject[];
  content?: string;
}

export type ProgressObject = {
  progress: number;
  statusContent: string;
};
